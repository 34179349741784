import request from '@utils/fetch'






//星推官接口

//商城信息
export function querycshopmallData(data) {
  return request({
    url: '/cshopmallHomeData/querycshopmallData',
    method: 'post',
    data
  })
}

//订单概述
export function querycshopmallOrderData(data) {
  return request({
    url: '/cshopmallHomeData/querycshopmallOrderData',
    method: 'post',
    data
  })
}

//商品销量排行
export function querycshopmallOrderGoods(data) {
  return request({
    url: '/cshopmallHomeData/querycshopmallOrderGoods',
    method: 'post',
    data
  })
}

//近7日交易走势
export function querycshopmallOrderLine(data) {
  return request({
    url: '/cshopmallHomeData/querycshopmallOrderLine',
    method: 'post',
    data
  })
}

//用户列表
export function querycshopmallUserPage(data) {
  return request({
    url: '/cshopmallUser/querycshopmallUserPage',
    method: 'post',
    data
  })
}

//用户列表-修改用户
export function updatecshopmallUser(data) {
  return request({
    url: '/cshopmallUser/updatecshopmallUser',
    method: 'post',
    data
  })
}

//用户列表-积分明细
export function querycshopmallRegister(data) {
  return request({
    url: '/cshopmallRegister/querycshopmallRegister',
    method: 'post',
    data
  })
}

//用户列表-积分充值 扣除
export function updatecshopmallIntegral(data) {
  return request({
    url: '/cshopmallUser/updatecshopmallIntegral',
    method: 'post',
    data
  })
}

//积分调整记录
export function querycshopmallIntegralLogPage(data) {
  return request({
    url: '/cshopmallIntegralLog/querycshopmallIntegralLogPage',
    method: 'post',
    data
  })
}


//新增积分活动
export function addcshopmallKocActivity(data) {
  return request({
    url: '/cshopmallKocActivity/addcshopmallKocActivity',
    method: 'post',
    data
  })
}

//查询积分活动
export function querycshopmallKocActivityPage(data) {
  return request({
    url: '/cshopmallKocActivity/querycshopmallKocActivityPage',
    method: 'post',
    data
  })
}


//活动管理-详细查询
export function querycshopmallKocNotesPage(data) {
  return request({
    url: '/cshopmallKocNotes/querycshopmallKocNotesPage',
    method: 'post',
    data
  })
}

//活动管理-删除
export function updatecshopmallKocActivity(data) {
  return request({
    url: '/cshopmallKocActivity/updatecshopmallKocActivity',
    method: 'post',
    data
  })
}

//活动管理-积分审核 取消审核  1已审核，2驳回， 0 未审核
export function updatecshopmallKocNotes(data) {
  return request({
    url: '/cshopmallKocNotes/updatecshopmallKocNotes',
    method: 'post',
    data
  })
}

//活动管理-积分审核-删除
export function delcshopmallKocNotes(data) {
  return request({
    url: '/cshopmallKocNotes/delcshopmallKocNotes',
    method: 'post',
    data
  })
}

//活动管理-笔记管理-批量导出
export function querycshopmallKocNotesExport(data) {
  return request({
    url: '/cshopmallKocNotes/querycshopmallKocNotesExport',
    method: 'post',
    data
  })
}

//活动管理-笔记管理-添加
export function addcshopmallKocNotes(data) {
  return request({
    url: '/cshopmallKocNotes/addcshopmallKocNotes',
    method: 'post',
    data
  })
}

//活动管理-笔记管理-活动类型列表
export function querycshopmallKocActivity(data) {
  return request({
    url: '/cshopmallKocActivity/querycshopmallKocActivity',
    method: 'post',
    data
  })
}




//活动管理-轮播图列表
export function querycshopmallBanner(data) {
  return request({
    url: '/cshopmallBanner/querycshopmallBanner',
    method: 'post',
    data
  })
}

//活动管理-轮播图修改
export function updatecshopmallBanner(data) {
  return request({
    url: '/cshopmallBanner/updatecshopmallBanner',
    method: 'post',
    data
  })
}

//活动管理-轮播图删除
export function delcshopmallBanner(data) {
  return request({
    url: '/cshopmallBanner/delcshopmallBanner',
    method: 'post',
    data
  })
}

//活动管理-轮播图新增
export function addcshopmallBanner(data) {
  return request({
    url: '/cshopmallBanner/addcshopmallBanner',
    method: 'post',
    data
  })
}

//查询积分商品分页
export function querycshopmallIntegralGoodsPage(data) {
  return request({
    url: '/cshopmallIntegralGoods/querycshopmallIntegralGoodsPage',
    method: 'post',
    data
  })
}

//新增积分商品
export function addcshopmallIntegralGoods(data) {
  return request({
    url: '/cshopmallIntegralGoods/addcshopmallIntegralGoods',
    method: 'post',
    data
  })
}

//修改积分商品 ，下架，上架
export function updatecshopmallIntegralGoods(data) {
  return request({
    url: '/cshopmallIntegralGoods/updatecshopmallIntegralGoods',
    method: 'post',
    data
  })
}

//删除
export function delcshopmallIntegralGoods(data) {
  return request({
    url: '/cshopmallIntegralGoods/delcshopmallIntegralGoods',
    method: 'post',
    data
  })
}

//条件查询积分订单分页
export function paramcshopmallIntegralPage(data) {
  return request({
    url: '/cshopmallIntegralOrder/paramcshopmallIntegralPage',
    method: 'post',
    data
  })
}

//导出积分订单
export function querycshopmallIntegralOrder(data) {
  return request({
    url: '/cshopmallIntegralOrder/querycshopmallIntegralOrder',
    method: 'post',
    data
  })
}

//修改订单   发货  修改快递单号  同意请求 拒绝请求
export function updatecshopmallIntegralOrder(data) {
  return request({
    url: '/cshopmallIntegralOrder/updatecshopmallIntegralOrder',
    method: 'post',
    data
  })
}

//同意请求 拒绝请求
export function checkshopmallIntegralOrder(data) {
  return request({
    url: '/cshopmallIntegralOrder/checkshopmallIntegralOrder',
    method: 'post',
    data
  })
}

//内容列表新增内容
export function addcshopmallContent(data) {
  return request({
    url: '/cshopmallContent/addcshopmallContent',
    method: 'post',
    data
  })
}

//内容列表查询内容
export function querycshopmallContent(data) {
  return request({
    url: '/cshopmallContent/querycshopmallContent',
    method: 'post',
    data
  })
}

//内容列表修改内容
export function updatecshopmallContent(data) {
  return request({
    url: '/cshopmallContent/updatecshopmallContent',
    method: 'post',
    data
  })
}

//内容列表删除内容
export function delcshopmallContent(data) {
  return request({
    url: '/cshopmallContent/delcshopmallContent',
    method: 'post',
    data
  })
}

//活动管理编辑内容查询
export function querycshopmallKocActivityById(data) {
  return request({
    url: '/cshopmallKocActivity/querycshopmallKocActivityById',
    method: 'post',
    data
  })
}







































































